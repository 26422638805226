@import "transitions";
// @import "../../node_modules/react-daterange-picker/dist/css/react-calendar.css";
@import '../../node_modules/react-dates/lib/css/_datepicker.css';
@import "popup";


#loader {
  position: fixed;
  z-index: 9999;
  bottom:10px;
  right: 10px;
}

body.noscroll {
  overflow:hidden;
}

.pagination li a {
  cursor: pointer;
}

.DateRangePicker {

  // position: absolute;

  .DateInput {
    padding: 0;
  }

  .DateInput__display-text {
    text-align: center;
    font-size: 14px;
  }
  .DateRangePickerInput {
    border: 1px solid #ccc;
    border-radius: 2px;
  }

}


#EventList {
  .pagination {
    margin: 6px 0;
  }
  .eventsearch {
    margin-bottom: 0;
  }
  nav {
    text-align: center;
  }
}


.admin-form {
  margin-top: 25px;
}

.table > tbody > tr.cancellation-details > td {
  padding: 0;
}
.table > tbody > tr.cancellation-details .row {
  margin-left: -8px;
  margin-right: -8px;
}

.cancellation-details {
  background-color: $highlightColor;

  .table {
    background-color: $highlightColor;
  }

  h3 {
    font-size: 20px;
    margin-top: 10px;
    margin-left: 7px;
  }

}

.minibuttons .btn {
  margin: 0 0 2px 0;
}


.active-row {
  background-color: $highlightColor;
}

#app {
  .btn-warning.focus,
  .btn-warning:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d;
  }

  .panel-title {
    margin: 0;
  }
}

#EventList {
  .event:hover {
    background-color: $highlightColor;
  }
}

#reservations {
  .invoice-total {
    background-color: $highlightColor;
  }

  .invoice-total td,
  .invoice-total th {
    line-height: 35px;
  }
}
// #menuplaceholder {
//   height: 30px;
//   line-height: 30px;
//   border-bottom: 1px solid #073642;
//   background-color: #073642;
//   padding:0 20px;
//   margin-bottom: 15px;
//   text-align: center;
// }
//

// #pdfcontainer {
//   position: fixed;
//   top: 10px;
//   left: 10px;
//   bottom: 10px;
//   right: 10px;
// }

#pdfcontainer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 3px;
  right: 0;
  background-color: $highlightColor;
  z-index: 1000;
}

#pdfcontainer {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    right: 0;
    padding: 0 15px;
  }

  .btn-pdfclose {
    margin-top: 7px;
  }

  .pdfcontent {
    position: absolute;
    left: 0;
    top: 60px;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}

#pdfcontainer .pdfcontent object {
  width: 100%;
  height: 100%;
}
// #SelectForm {
//   h3 {
//     border-bottom: 1px solid #839496;
//   }
// }
// Input-Eingabefelder ohne die Pfeile des Browsers darstellen

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#EventList {
  .event {
    margin: 0;
    cursor: pointer;
    padding: 5px;
  }
}

#LoginForm {
  margin-top: 150px;
}

#Shop {
  .form-horizontal .amountselect .control-label {
    text-align: left;
  }

  .form-horizontal .amountselect .form-control {
    text-align: right;
  }

  .img-left {
    max-width: 180px;
    float: left;
    margin: 0 10px 10px 0;
  }
}

.table .table {
  margin-bottom: 0;
}

.ticketsummary {
  ul {
    margin: 0;
    padding: 0;
  }

  .invoice-total {
    background-color: $highlightColor;
  }

  .invoice-total td,
  .invoice-total th {
    line-height: 50px;
  }
}
