$highlightColor: #f5f5f5;

// Core variables and mixins
@import "bootstrap-variables.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins.scss";


// Reset and dependencies
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons.scss";

// Core CSS
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons.scss";

// Components
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close.scss";

// Components w/ JavaScript
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers.scss";
// @import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel.scss";

// Utility classes
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities.scss";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities.scss";


@import "base";
